<template>
    <modal ref="modalImagenes" titulo="Imagenes" no-aceptar tamano="modal-xl">
        <ValidationObserver ref="validacion">
            <cargando v-if="cargando" />
            <div class="d-middle mx-0 bg-fondo w-100 position-absolute header-modal">
                <div class="col d-middle border-right h-100 pl-5">
                    <el-upload
                    ref="archive"
                    class="upload-demo"
                    action="#"
                    :multiple="false"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="upFile"
                    :on-remove="removeFile"
                    :limit="1"
                    accept="image/*"
                    >
                        <button type="button" class="btn-anadir-imagen cr-pointer f-14">
                            Añadir imagen
                        </button>
                    </el-upload>
                </div>
                <div class="col-4 px-3 h-100 d-middle" style="width: 325px;">
                    <span class="text-general f-16">
                        Parámetros
                    </span>
                </div>
            </div>
            <div class="row mx-0 py-4" />
            <div class="row mx-0">
                <div v-if="imagenes.length < 1" class="col pl-5 pt-4">
                    <p class="text-general f-16 mb-3">
                        No se han cargado imagenes
                    </p>
                </div>
                <div v-else class="col pl-5 pt-4">
                    <p class="text-general f-16 mb-3">
                        Vista previa
                    </p>
                    <div class="d-flex flex-wrap">
                        <div v-for="(image,index) in imagenes" :key="index" class="bg-fondo p-2 br-4">
                            <img :src="image.imagen" class="br-4 obj-cover" width="100%" height="123" />
                        </div>
                    </div>
                    <p class="text-general f-16 mt-4 mb-3">
                        Imagenes
                    </p>
                    <draggable
                    :list="imagenes"
                    :disabled="!enabled"
                    class="row mx-0"
                    @change="checkMove"
                    @start="dragging = true"
                    @end="dragging = false"
                    >
                        <div v-for="(img, idx) in imagenes" :key="idx" class="position-relative mx-3 mb-3 my-3">
                            <img :src="img.imagen" width="123" height="123" class="obj-cover" />
                            <div class="col-12 position-absolute d-middle-center">
                                <div class="my-2 d-middle-center" style="gap: 2px;">
                                    <div v-for="(dy, d) in img.diasHabiles" :key="d" class="cr-pointer d-flex">
                                        <div class="bg-general text-white rounded-pill px-1" :class="`${dy.activo ? '' : 'op-05'}`" @click="diasSemanaActualizar(dy, img)">
                                            <p class="f-12"> {{ dy.dia }} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="img.estado == 0" class="cr-pointer position-absolute rounded-circle d-middle-center z-1 d-middle-center" style="top:5px;left:5px;background-color:#FF1720;width:24px;height:24px;">
                                <el-tooltip content="Inactiva" placement="bottom" effect="light">
                                    <i class="icon-eye-off text-white f-14" />
                                </el-tooltip>
                            </div>
                            <div v-if="img.showEnlace == 1" class="cr-pointer position-absolute rounded-circle d-middle-center z-1 d-middle-center" style="top:5px;right:5px;background-color:#29D884;width:24px;height:24px;">
                                <el-tooltip placement="top" effect="light">
                                    <div slot="content">
                                        Enlace<br />{{ showEnlaceImg(img.enlace, img.redireccion) }}
                                    </div>
                                    <i class="icon-link text-white f-18" />
                                </el-tooltip>
                            </div>
                            <el-popover ref="optionImage" position="bottom" effect="light" popper-class="br-8">
                                <div v-if="img.showEnlace == 0" class="row mx-0 p-2 f-14 item-popover" @click="emitEvent(1, img.id, idx)">
                                    Añadir enlace
                                </div>
                                <div v-if="img.showEnlace == 1" class="row mx-0 p-2 f-14 item-popover" @click="emitEvent(2, img.id, idx)">
                                    Editar enlace
                                </div>
                                <div v-if="img.estado == 1" class="row mx-0 p-2 f-14 item-popover" @click="changeStateImage(img.id, 'inactivar', idx)">
                                    Inactivar imagen
                                </div>
                                <div v-if="img.estado == 0" class="row mx-0 p-2 f-14 item-popover" @click="changeStateImage(img.id, 'activar', idx)">
                                    Activar imagen
                                </div>
                                <div v-if="img.showEnlace == 1" class="row mx-0 p-2 f-14 item-popover" @click="deleteEnlace(img.id, idx)">
                                    Eliminar enlace
                                </div>
                                <div class="row mx-0 p-2 f-14 item-popover" @click="deleteImage(img.id, idx)">
                                    Eliminar imagen
                                </div>
                                <div slot="reference" class="options-box br-8 cr-pointer position-absolute d-middle-center">
                                    <i class="icon-dots-vertical text-general text-white" />
                                </div>
                            </el-popover>
                        </div>
                    </draggable>
                </div>
                <div class="pl-3 pt-4 border-left" style="width:310px">
                    <p class="text-general f-16 mb-3">
                        Formato
                    </p>
                    <div class="row mx-0 mb-5" @change="updateSection('tipo', type)">
                        <el-radio v-model="type" :label="11" class="radio-green">
                            Slider
                        </el-radio>
                        <el-radio v-model="type" :label="12" class="radio-green">
                            Fijo
                        </el-radio>
                    </div>
                    <div class="row mx-0">
                        <div class="col-12 px-0">
                            <small class="pl-3 text-general">
                                Alto en porcentaje del contenedor
                            </small>
                            <ValidationProvider v-slot="{errors}" rules="required|numeric|min_value:2|max_value:40" name="Alto">
                                <el-input v-model="parameter" class="w-100" size="small" placeholder="porcentaje" @keyup.enter.native="updateSection('parametro', parameter)" @change="updateSection('parametro', parameter)" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                            <small class="text-grey f-12 mt-2 ml-3">
                                Mínimo 2% - Máximo 40%
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Service from '~/services/landing/landing_sections'
export default {
    data(){
        return {
            cargando: false,
            model:{
                idSection: null,
                file: null,
            },
            imagenes: [],
            type: 11,
            parameter: 40,
            enabled: true,
            dragging: false,
            dias: [
                { id: 1, dia: 'L', activo: false },
                { id: 2, dia: 'M', activo: true },
                { id: 3, dia: 'X', activo: false },
                { id: 4, dia: 'J', activo: true },
                { id: 5, dia: 'V', activo: true },
                { id: 6, dia: 'S', activo: true },
                { id: 7, dia: 'D', activo: true }
            ],
        }
    },
    computed:{
        idLanding(){
            return Number(this.$route.params.idLanding)
        },
    },
    methods: {
        toggle(bandera, idSection){
            this.clear();

            if(bandera === 'editar'){
                if(!idSection)return;

                this.model.idSection = idSection;
                this.getElementsSection();
            } else {
                if(!this.idLanding)return;

                this.postSection();
            }

            this.$refs.modalImagenes.toggle();
        },

        async getElementsSection(){
            try {
                const {data} = await Service.getElementsSection(this.model.idSection);
                this.parameter = data.section.parametro;
                this.type = parseInt(data.section.tipo);
                this.imagenes = data.data;
            } catch(e){
                this.error_catch(e);
            }
        },

        async postSection(){
            try {
                const model = {
                    type: this.type,
                    parameter: this.parameter,
                    idLanding: this.idLanding
                };
                const {data} = await Service.postSection(model);
                if(data.exito){
                    this.model.idSection = data.section;
                    this.$store.commit('landing/landing/setListenerUpdater', {value:'imagen', type: 'nuevaData', idSection: null});
                }
            } catch(e){
                this.error_catch(e);
            }
        },

        async updateSection(column, value){
            try {
                const valid = await this.$refs.validacion.validate();
                if(!valid)return;

                const model = {
                    column: column,
                    value: value
                };
                const {data} = await Service.updateSection(this.model.idSection, model)
                if(data.exito){
                    if(column == 'parametro' || column == 'tipo'){
                        this.$emit('updateParametro');
                    }

                    this.notificacion('Mensaje', 'Se ha actualizado correctamente', 'success');
                    this.$refs.validacion.reset();
                }
            } catch(e){
                this.error_catch(e)
            }
        },

        upFile(file){
            let type = file.raw.type.split('/');

            if(type.length > 0){
                for(let i = 0; i < type.length; i++){
                    if(type[i] == 'image'){
                        this.model.file = file.raw;
                        return this.postImage();
                    }
                }
            }

            this.notificacion('No es posible', 'El archivo que intenta subir no es una imagen', 'warning');
            this.removeFile();
        },

        async postImage(){
            try {
                if(!this.model.file)return;

                this.cargando = true;

                let model = this.crear_formData(this.model)
                const {data} = await Service.postImage(model)
                if(data.exito){
                    this.notificacion('Mensaje', 'La imagen se ha guardado correctamente', 'success');
                    this.imagenes.push(data.newImage);
                    this.removeFile();

                    if(this.imagenes.length < 2){
                        this.$store.commit('landing/landing/setListenerUpdater', {value:'imagen', type: 'nuevaData', idSection: null});
                    } else {
                        this.$store.commit('landing/landing/setListenerUpdater', {value:'imagen', type: 'actualizarData', idSection: this.model.idSection});
                    }
                }
            } catch(e){
                this.error_catch(e)
            } finally{
                this.cargando = false;
            }
        },

        removeFile(){
            this.model.file = null;
            this.$refs.archive.clearFiles();
        },

        checkMove: function(e){

			const ids = this.imagenes.map((e) => e.id)
            this.updateOrdenImages(ids);
        },

        async updateOrdenImages(ids){
            try {
                const model = {
                    ids
                };
                const {data} = await Service.updateOrdenImages(this.model.idSection, model);
				if(data) this.notificacion('Éxito', 'Posiciòn actualizada correctamente', 'success')
            } catch(e){
                this.error_catch(e)
            }
        },

        async emitEvent(bandera, id, popover){
            this.cerrarPopover(popover);
            switch(parseInt(bandera)){
            case 1:
                this.$refs.modalImagenes.toggle();
                this.$emit('goEventsImages', {bandera:'asignar', idSection: this.model.idSection, idElementSection: id});
                break;
            case 2:
                this.$refs.modalImagenes.toggle();
                this.$emit('goEventsImages', {bandera:'editar', idSection: this.model.idSection, idElementSection: id});
                break;
            case 3:
                this.$emit('update');
            }
        },

        async deleteImage(idImage, popover){
            try {
                this.cargando = true;

                const {data} = await Service.deleteImage(idImage)
                if(data.exito){
                    this.emitEvent(3, null, popover);
                    this.notificacion('Mensaje', data.mensaje, data.tipo)
                    this.imagenes = data.imagenes;
                }
            } catch(e){
                this.error_catch(e)
            } finally{
                this.cargando = false;
            }
        },

        async deleteEnlace(idImage, index){
            try {
                const {data} = await Service.deleteEnlace(idImage);
                if(data.exito){
                    this.cerrarPopover(index);
                    this.notificacion('Mensaje', 'El enlace se ha eliminado', 'success');

                    this.imagenes[index].showEnlace = 0;
                    delete this.imagenes[index].enlace;
                }
            } catch(e){
                this.error_catch(e)
            }
        },

        async changeStateImage(idImage, action, index){
            try {
                let value = action === 'activar' ? 1 : 0;
                const model = {
                    value: value
                };
                const {data} = await Service.changeStateElement(idImage, model)

                if(action == 'inactivar'){
                    this.notificacion('Mensaje', 'La imagen se ha inactivado', data.tipo);
                } else {
                    this.notificacion('Mensaje', 'La imagen se ha activado', data.tipo);
                }

                this.imagenes[index].estado = value;

                this.cerrarPopover(index);
            } catch(e){
                this.error_catch(e)
            }
        },

        showEnlaceImg(enlace, redirect){
            if(parseInt(redirect) != 11 && parseInt(redirect) != 12 && parseInt(redirect) != 13 && parseInt(redirect) != 24){
                return enlace.tipo;
            }
            return `${enlace.tipo}: ${enlace.nombre}`;
        },

        cerrarPopover(popover){
            this.$refs.optionImage[parseInt(popover)].doToggle();
        },
        async clear(){
            this.cargando = false;
            this.imagenes = [];
            this.model.idSection = null;
            this.model.file = null;
            this.type = 11;
            this.parameter = 40;
            this.enabled = true;
            this.dragging = false;
            this.removeFile();
        },
        async diasSemanaActualizar(value, img){
            try {
                value.activo = !value.activo
    
                let diasHabil = []
                img.diasHabiles.forEach(function(item){
                    if (item.activo == false){
                        diasHabil.push(0)
                    }else {
                        diasHabil.push(1)
                    }
                })
    
                let strinDias = `[${String(diasHabil)}]`
    
                const {data} = await Service.putDiasHabiles(img.id, {'diasHabiles' : strinDias});
                if(data.data) this.notificacion('Mensaje', 'Día actualizado correctamente');
                
            } catch(e){
                this.error_catch(e)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.header-modal{
    height: 53px;
    left: 0px;
}

.btn-anadir-imagen{
    width: 165px;
    height: 28px;
    border: 1px solid #DBDBDB;
    border-radius: 16px;
    background-color: #FFFFFF;
}
.options-box{
    width:35px;
    height:35px;
    bottom:10px;
    left:35%;
    background-color: #00000070;
}
.item-popover:hover{
    cursor: pointer;
    background-color: #F6F9FB;
}
</style>
